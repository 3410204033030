
const preloader = () => {

    // const content = document.querySelector('.after-preload') as HTMLDivElement
    // const overlay = document.querySelector('.preloader') as HTMLDivElement

    const start = () => {

        // console.log('hide preloader')

        // // if (!content) return

        // setTimeout(() => {

        //     // content.style.opacity = '1'
        //     // overlay.style.opacity = '0'
        //     overlay.style.display = 'none'
        // }, 1000)
    }

    return {
        start
    }
}

export default preloader().start
