
import Glide from '@glidejs/glide'

import './logos.scss'

const initGlide = () => {

    const element = document.querySelector('.glide')

    if (!element) return

    new Glide('.glide-logos', {
        type: 'carousel',
        autoplay: 2000,
        hoverpause: false,
        perView: 6,
        breakpoints: {
            425: {
                perView: 1
            }
        }
    }).mount()
}

export default initGlide
