
import Glide from '@glidejs/glide'

import "./slider.scss"

const slider = () => {


    const start = () => {

        const sliderContent = document.querySelector('.glide-slider')

        if (!sliderContent) return

        new Glide('.glide-slider', {
            type: 'carousel',
            autoplay: 5000,
            hoverpause: true,
            perView: 1,
        }).mount()
    }

    return {
        start
    }
}

export default slider().start
