
import sliderStart from './slider/slider'
import reasonsStart from './reasons/reasons'

import initGlide from "./logos/logos";

import preloaderStart from './preloader/preloader'

import './main.scss'

sliderStart()
reasonsStart()

initGlide()

preloaderStart()
