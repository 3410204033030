
import Glide from '@glidejs/glide'

import './reasons.scss'

const reasons = () => {

    const start = () => {

        const reasonsContent = document.querySelector('.glide-reasons')

        if (!reasonsContent) return

        new Glide('.glide-reasons', {
            type: 'carousel',
            autoplay: 4000,
            hoverpause: true,
            perView: 3,
            breakpoints: {
                425: {
                    perView: 1
                }
            }
        }).mount()
    }

    return {
        start
    }
}

export default reasons().start
